<template>
  <div class="busCard">
    <v-row no-gutters align="start">
      <v-col
        class="py-0"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        style="padding-right: 10px; position: relative"
      >
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          style="position: absolute; width: 100%; padding: 0px 10px"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 110px;'
              : 'margin-top: 180px;margin-left: 10px;'
          "
        >
          <div
            class="iconBackgroundBus"
            :class="`swiper-button-prev-bus swiper-button-prev-bus-${bus.id}`"
            style="z-index: 10"
            slot="button-prev"
          >
            <img
              src="@/assets/img/iconsSvg/swiperRightIcon.svg"
              alt="right icon"
              style="transform: rotate(180deg)"
            />
          </div>
          <div
            style="
              box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
              z-index: 10;
            "
            :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-right: 30px'"
            class="iconBackgroundBus"
            slot="button-next"
            :class="`swiper-button-next-bus swiper-button-next-bus-${bus.id}`"
          >
            <img
              src="@/assets/img/iconsSvg/swiperRightIcon.svg"
              alt="right icon"
            />
          </div>
        </v-row>
        <swiper
          ref="mySwiper"
          :options="getSwiperOptions(bus.id)"
          class="busCardImgSwiper"
        >
          <swiper-slide
            v-for="img in bus.images"
            :key="img.id"
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="getSwiperOptions?.modules"
          >
            <img
              :src="img?.path"
              width="100%"
              height="370px"
              style="object-fit: cover"
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </v-col>
      <v-col
        class="py-0"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px'"
        style="text-align: left"
      >
        <h4 class="busModel">{{ bus?.manufacturer }} {{ bus?.model }}</h4>
        <v-row no-gutters align="center" style="margin-top: 20px">
          <div class="descriptionValueRow" style="margin-bottom: 12px">
            <span>{{ "bus_graduation_year_label" | localize }}</span>
            <div class="busDescriptionValue">{{ bus?.year }}</div>
          </div>
          <div class="descriptionValueRow" style="margin-bottom: 12px">
            <span>{{ "bus_color_label" | localize }}</span>
            <div class="busDescriptionValue">
              {{ bus?.translations?.color }}
            </div>
          </div>
          <div class="descriptionValueRow" style="margin-bottom: 12px">
            <span>{{ "bus_quantity_seats_label" | localize }}</span>
            <div class="busDescriptionValue">{{ bus?.seats }}</div>
          </div>
          <div class="descriptionValueRow" style="margin-bottom: 12px">
            <span>{{ "bus_floor_area_label" | localize }}</span>
            <div class="busDescriptionValue">
              {{ bus?.translations?.floors }}
            </div>
          </div>
          <div class="descriptionValueRow" style="margin-bottom: 12px">
            <span>{{ "bus_govermant_number_label" | localize }}</span>
            <div class="busDescriptionValue">{{ bus?.government_number }}</div>
          </div>
        </v-row>
        <v-expand-transition>
          <v-col class="px-0 py-0" v-if="isShowDetailInfo">
            <p class="busCardSectionName" style="margin: 8px 0px 0px 0px">
              {{ "description_label" | localize }}
            </p>
            <p
              style="
                margin: 12px 0px 0px 0px;
                text-overflow: ellipsis;
                overflow: hidden;
              "
            >
              {{ bus?.translations?.description }}
            </p>
            <p class="busCardSectionName" style="margin: 20px 0px 0px 0px">
              {{ "bus_additional_services_label" | localize }}
            </p>
            <v-row no-gutters align="center" style="margin-top: 12px">
              <button
                class="busServiceBox"
                v-for="service in bus.services"
                :key="service.id"
              >
                {{ service.translations?.name }}
              </button>
            </v-row>
          </v-col>
        </v-expand-transition>
        <button
          class="detailBtn"
          @click="isShowDetailInfo = !isShowDetailInfo"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          {{ isShowDetailInfo ? "Сховати" : "Детальніше" }}
          <img
            style="margin-left: 10px"
            src="@/assets/img/iconsSvg/chevronDown.svg"
            alt="down"
            :style="isShowDetailInfo ? 'transform: rotate(180deg)' : ''"
          />
        </button>
        <submit-button>{{ "rent_bus_btn" | localize }}</submit-button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swiperOptionMixin from "../../../mixins/swiperOptionMixin";
import submitButton from "../../UI/Buttons/submitButton.vue";
export default {
  components: { submitButton },
  mixins: [swiperOptionMixin],
  data: () => ({
    isShowDetailInfo: false,
  }),
  props: {
    bus: {
      require: false,
    },
  },
  mounted() {
    if (!this.$vuetify.breakpoint.smAndDown) {
      this.isShowDetailInfo = true;
    } else {
      this.isShowDetailInfo = false;
    }
  },
  methods: {
    getSwiperOptions(busId) {
      return {
        spaceBetween: 40,
        navigation: {
          nextEl: `.swiper-button-next-bus-${busId}`,
          prevEl: `.swiper-button-prev-bus-${busId}`,
        },
      };
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1023px) {
  .busCard {
    border-radius: 10px;
    background: #f5f5f5;
    padding: 20px;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .busModel {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }
  .busCardSectionName {
    font-size: 20px;
    font-weight: 500;
  }
  .descriptionValueRow {
    display: flex;
    align-items: center;
  }
  .busDescriptionValue {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    display: grid;
    place-items: center;
    color: #144fa9;
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: max-content;
    height: 30px;
    margin: 0px 12px 0px 12px;
  }
  .busServiceBox {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .iconBackgroundBus {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    background: #fafafa;
    box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;
  }
}
@media screen and (min-width: 1024px) {
  .busCard {
    border-radius: 10px;
    background: #f5f5f5;
    padding: 20px;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .busModel {
    font-size: 32px;
    font-weight: 600;
  }
  .busCardSectionName {
    font-size: 20px;
    font-weight: 500;
  }
  .descriptionValueRow {
    display: flex;
    align-items: center;
  }
  .busDescriptionValue {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    display: grid;
    place-items: center;
    color: #144fa9;
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: max-content;
    height: 30px;
    margin: 0px 12px 0px 12px;
  }
  .busServiceBox {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .iconBackgroundBus {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    background: #fafafa;
    box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;
  }
}
.detailBtn {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  display: flex;
  height: 40px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin: 20px 0px;
}
</style>
<style>
.busCardImgSwiper .swiper-pagination-bullet {
  background: #989898 !important;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.busCardImgSwiper .swiper-pagination-bullet-active {
  background: #144fa9 !important;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.busCardImgSwiper .swiper-pagination {
  position: static;
  margin-top: 8px;
}
</style>