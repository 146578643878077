<template>
  <v-col class="px-0 py-0" style="text-align: center">
    <v-main>
      <v-container>
        <page-step :pages="[{ id: 1, name: 'Автопарк', link: '/our_fleet' }]" />
        <h2
          class="pageName"
          :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 40px;' : ''"
        >
          {{ "our_fleet_section" | localize }}
        </h2>
        <p class="pageText" style="margin: 12px 0px 0px 0px">
          {{ "our_fleet_subtitle" | localize }}
        </p>
        <loader v-if="showLoader" />
        <v-col class="px-0 py-0" v-else>
          <v-row no-gutters justify="center" style="margin-top: 20px">
            <category-list
              :categoryList="categoryList"
              @setCategory="setBusManufacturer"
            />
          </v-row>
          <loader v-if="showBusLoader" />
          <v-col v-else class="px-0 py-0" style="margin-top: 40px">
            <bus-card
              v-for="(bus, index) in busList"
              :id="'bus-' + bus.id"
              :key="bus.id"
              :index="index"
              :bus="bus"
              style="margin-bottom: 20px"
            />
          </v-col>
        </v-col>
      </v-container>
    </v-main>
  </v-col>
</template>

<script>
import BusCard from "./busCard.vue";
import CategoryList from "../../UI/Lists/categoryList.vue";
import PageStep from "../../UI/pageStep.vue";
import busService from "../../../requests/Bus/busService";
import Loader from "../../UI/Loader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PageStep,
    CategoryList,
    BusCard,
    Loader,
  },
  data: () => ({
    busList: [],
    categoryList: [],
    showLoader: true,
    showBusLoader: false,
    page: 1,
    quantityPage: 0,
    activeManufacturer: 0,
  }),
  mounted() {
    this.getBuses();
    this.getBusManufacturer();
  },
  methods: {
    async getBuses() {
      await busService
        .getBuses(this.activeManufacturer, this.page)
        .then((res) => {
          if (res.status == "Success") {
            this.busList = res.data;
            this.quantityPage = res.pagination.meta.last_page;
            if (Object.values(this.$route.query).length > 0) {
              setTimeout(() => {
                this.$vuetify.goTo(`#bus-${this.$route.query.bus_id}`);
              }, 500);
            }
          }
        });
      this.showBusLoader = false;
      this.showLoader = false;
    },
    async getBusManufacturer() {
      await busService.getBusManufacturer().then((res) => {
        if (res.status == "Success") {
          this.categoryList = res.data.map(
            (category) =>
              (category = {
                translations: {
                  slug: category.manufacturer,
                  name: category.manufacturer,
                },
              })
          );
          this.categoryList.unshift({
            translations: {
              slug: 0,
              name: "Усі",
            },
          });
        }
      });
    },
    setBusManufacturer(manufacturer) {
      this.showBusLoader = true;
      this.activeManufacturer = manufacturer;
      this.getBuses();
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    locale: {
      handler() {
        this.showBusLoader = true;
        this.getBuses();
      },
    },
  },
};
</script>

<style scoped>
</style>